export const LOCALES = ["pl", "en"];

export const t = (function () {
  const defaultLocale = "pl";
  let currentLang;
  let translations = {};
  translate.load = importTranslations;
  return Object.freeze(translate);

  function translate(path, source = translations) {
    if (!path) return undefined;
    const components = path.split(".");
    try {
      return components.reduce((acc, key, idx) => {
        let val = acc[key];
        if (val) {
          // before reaching the last item always proceed with value
          if (idx < components.length - 1) return val;

          // last item is leaf node, return its value
          if (typeof val === "string" || Array.isArray(val)) return val;

          // last item is object, return t function starting from this object
          return (path) => t(path, val);
        } else {
          // wrong path item, stop and throw
          throw new Error(`Missing translation for key: ${key}`);
        }
      }, source);
    } catch (err) {
      return err.message;
    }
  }

  function importTranslations(locale) {
    const lang = LOCALES.includes(locale) ? locale : defaultLocale;
    return import(`../locales/${lang}.json.proxy.js`)
      .then((module) => {
        if (currentLang !== lang) {
          currentLang = lang;
          translations = module.default;
          window.dispatchEvent(new CustomEvent("locale", { detail: { lang } }));
        }
        return lang;
      })
      .catch((err) => {
        console.log(`Error importing locale ${lang}`, err);
        // try simple code
        const simpleCode = lang.split("-")[0];
        if (simpleCode !== lang) return importTranslations(simpleCode);
        // nothing for the current code - load defaults
        if (lang !== defaultLocale) return importTranslations(defaultLocale);
      });
  }
})();

function storeLang({ detail }) {
  localStorage.setItem("lang", detail.lang);
}

export function getLang() {
  return localStorage.getItem("lang");
}

window.addEventListener("locale", storeLang);

export function detectLang() {
  return getLang() || detectLangFromBrowserSettings();
}

function detectLangFromBrowserSettings() {
  return window.navigator.language;
}

export function setupReduxLocale(store, dispatcher) {
  let watcherId = ({ detail }) => dispatcher(store, detail.lang);

  window.addEventListener("locale", watcherId);
  return () => window.removeEventListener("locale", watcherId);
}

let pattern = new RegExp("%%");
let patternGlobal = new RegExp(pattern.source, pattern.flags + "g");

// export function interpolate2(text, ...values) {
//   let interpolated = text;
//   let i = 0;
//   while (pattern.test(interpolated)) {
//     interpolated = interpolated.replace(pattern, values[i++]);
//   }
//   return interpolated;
// }

export function interpolate(text, ...values) {
  let interpolated = String(text);
  let i = 0;
  (interpolated.match(patternGlobal) || []).forEach((_) => {
    interpolated = interpolated.replace("%%", values[i++]);
  });
  return interpolated;
}
