import "./css/fonts.css";
import { detectLang, t } from "./lib/i18n";
import * as serviceWorker from "./serviceWorker";

Promise.all([
  t.load(detectLang()),
  // t.load("pl"),
  import("./app/AppLoader"),
]).then(([lang, { boot }]) => boot(lang));

serviceWorker.register({
  onUpdate(registration) {
    serviceWorker.checkForUpdates(registration);
  },
});
